import { Box, DialogProps } from '@mui/material';
import React from 'react';
import { DialogAnimate } from '~components/animate';
import useResponsive from '~hooks/useResponsive';
import ReminderContent from './ReminderContent';
import { useGetOccasionsQuery } from '~screens/shared/services/reminderApi';
interface Props extends DialogProps {
  onReset: any;
}
function Reminder({
  open,
  onReset
}: Props) {
  const isMobile = useResponsive('down', 'sm');
  const isMd = useResponsive('down', 'md');
  const {
    data: occasionsList = [],
    isLoading: loadingOccasions
  } = useGetOccasionsQuery({});
  return <DialogAnimate className={isMd ? 'dialog-animate-full' : ''} fullScreen={isMd} open={open} sx={{
    //  border: '0px solid red',
  }} data-sentry-element="DialogAnimate" data-sentry-component="Reminder" data-sentry-source-file="Reminder.tsx">
      {loadingOccasions ? <Box>{'loading...'}</Box> : <ReminderContent onReset={onReset} occasionsList={occasionsList} />}
    </DialogAnimate>;
}
export default Reminder;