import { Box, Grid, Typography, useTheme } from '@mui/material';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import StepActive from 'public/assets/icons/step-active.svg';
import StepInactive from 'public/assets/icons/step-inactive.svg';
import { useEffect, useState } from 'react';
import CarouselDots from '~components/carousel-dots/CarouselDots';
import { wayWeDoSteps } from '~config/constants/lists';
import useResponsive from '~hooks/useResponsive';
const QontoConnector = styled(StepConnector)(({
  theme: {
    palette: {
      primary,
      text
    }
  }
}: any) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: text.disabledLight,
    borderTopWidth: 1,
    borderRadius: 1
  }
}));
const StepDetail = ({
  data
}: any) => {
  const {
    id,
    label,
    details
  } = data;
  const {
    palette: {
      primary,
      common
    }
  }: any = useTheme();
  return <Box key={label} sx={{
    border: '0px solid red',
    padding: 3,
    marginTop: 3,
    marginLeft: {
      md: '0px',
      xs: '4%'
    },
    borderRadius: '16px 0 0 16px',
    backgroundColor: 'rgba(50, 93, 127, 0.08)',
    display: 'flex'
  }} data-sentry-element="Box" data-sentry-component="StepDetail" data-sentry-source-file="Carousel.tsx">
      <Box alignSelf="center" sx={{
      border: '0px solid red'
    }} data-sentry-element="Box" data-sentry-source-file="Carousel.tsx">
        <Typography fontSize="120px" fontWeight="900" lineHeight="64px" sx={{
        border: '0px solid red',
        color: primary.main,
        display: {
          xs: 'none',
          md: 'block'
        }
      }} data-sentry-element="Typography" data-sentry-source-file="Carousel.tsx">
          {id}.
        </Typography>
      </Box>
      <Box alignSelf="center" data-sentry-element="Box" data-sentry-source-file="Carousel.tsx">
        <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        border: '0px solid red'
      }} data-sentry-element="Box" data-sentry-source-file="Carousel.tsx">
          <Typography variant="h1" fontSize="40px" fontWeight="900" lineHeight="50px" sx={{
          border: '0px solid red',
          color: primary.main,
          display: {
            xs: 'block',
            md: 'none'
          },
          sx: {
            ml: {
              md: 1,
              xs: '0'
            }
          }
        }} data-sentry-element="Typography" data-sentry-source-file="Carousel.tsx">
            {id}.
          </Typography>
          <Typography variant="h3" sx={{
          border: '0px solid red',
          mb: '16px',
          color: primary.main,
          fontSize: {
            xs: '32px'
          },
          fontWeight: {
            xs: '700'
          }
        }} data-sentry-element="Typography" data-sentry-source-file="Carousel.tsx">
            {label}
          </Typography>
        </Box>
        <Typography sx={{
        border: '0px solid red',
        mb: '16px',
        color: common.black,
        fontSize: {
          md: '16px'
        },
        fontWight: '500'
      }} data-sentry-element="Typography" data-sentry-source-file="Carousel.tsx">
          {details}
        </Typography>
      </Box>
    </Box>;
};
const MainStepper = ({
  active
}: any) => {
  const {
    palette: {
      primary,
      text
    }
  }: any = useTheme();
  return <Box sx={{
    border: '0px solid red',
    width: {
      md: '50%',
      xs: '100%'
    },
    display: {
      xs: 'flex',
      md: 'block'
    },
    justifyContent: 'center'
  }} data-sentry-element="Box" data-sentry-component="MainStepper" data-sentry-source-file="Carousel.tsx">
      <Stepper sx={{
      border: '0px solid red',
      width: '340px'
    }} activeStep={active} alternativeLabel connector={<QontoConnector sx={{
      marginX: '8px'
    }} />} data-sentry-element="Stepper" data-sentry-source-file="Carousel.tsx">
        {wayWeDoSteps.map((item, index) => <Step key={item.label}>
            <StepLabel StepIconComponent={() => index > active ? <StepInactive height={27} width={27} /> : <StepActive height={27} width={27} />}>
              <Typography fontWeight="400" fontSize="14px" color={index > active ? text.disabled : primary.main} sx={{
            border: '0px solid red',
            width: {
              md: '120px'
            }
          }}>
                {item.label}
              </Typography>
            </StepLabel>
          </Step>)}
      </Stepper>
    </Box>;
};
function Carousel() {
  const {
    palette: {
      primary,
      common
    }
  }: any = useTheme();
  const isMobile = useResponsive('down', 'sm');
  const [active, setActive] = useState(0);
  const [intervals, setIntervals] = useState<any>([]);
  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prev: any) => {
        if (prev == 2) return 0;else return prev + 1;
      });
    }, 5000);
    setIntervals((prev: any) => {
      return [...prev, interval];
    });
    return () => intervals?.forEach?.((item: any) => clearInterval(item));
  }, []);
  return <Grid xs={12} md={10} alignItems="flex-end" justifyContent="flex-end" sx={{}} item data-sentry-element="Grid" data-sentry-component="Carousel" data-sentry-source-file="Carousel.tsx">
      <Typography variant="h2" sx={{
      border: '0px solid red',
      mb: '55px',
      mt: '16px',
      color: primary.main,
      textAlign: {
        xs: 'center',
        md: 'left'
      }
    }} data-sentry-element="Typography" data-sentry-source-file="Carousel.tsx">
        <span style={{
        color: common.black
      }}>
          That's the
          <span style={{
          marginLeft: 10,
          color: isMobile ? primary.main : common.black
        }}>
            <br style={{
            display: isMobile ? 'inline-block' : 'none'
          }} />
            way we do it{' '}
          </span>
        </span>
      </Typography>
      <MainStepper active={active} data-sentry-element="MainStepper" data-sentry-source-file="Carousel.tsx" />
      <StepDetail data={wayWeDoSteps[active]} key={wayWeDoSteps[active].id} data-sentry-element="StepDetail" data-sentry-source-file="Carousel.tsx" />
      <Box sx={{
      justifyContent: 'center',
      display: 'flex',
      mt: '24px',
      mb: '48px'
    }} data-sentry-element="Box" data-sentry-source-file="Carousel.tsx">
        <CarouselDots active={active} steps={wayWeDoSteps.length} setActive={(index: any) => setActive(index)} data-sentry-element="CarouselDots" data-sentry-source-file="Carousel.tsx" />
      </Box>
    </Grid>;
}
export default Carousel;