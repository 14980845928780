import { Box } from '@mui/material';
function CarouselDots({
  steps,
  active,
  setActive
}: any) {
  return <Box data-sentry-element="Box" data-sentry-component="CarouselDots" data-sentry-source-file="CarouselDots.tsx">
      {[...Array(steps)].map((item: any, key: any) => active == key ? <span key={key} style={{
      cursor: 'pointer',
      height: '8px',
      width: '18px',
      margin: '0px 5px',
      borderRadius: '8px',
      display: 'inline-block',
      backgroundColor: '#EF7484'
    }} /> : <span key={key} onClick={() => setActive(key)} style={{
      cursor: 'pointer',
      height: '8px',
      width: '8px',
      margin: '0px 4px',
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: '#EF7484',
      opacity: '.5'
    }} />)}
    </Box>;
}
export default CarouselDots;